import { IOption, ObjectMap } from '@types';

/**
 * Типы условий фильтрации контента
 */
export const FilterConditionTypes: ObjectMap<IOption[]> = {
  'String': [
    { value: 'Equal', label: 'Равно', selected: true },
    { value: 'Contains', label: 'Содержит' },
    { value: 'StartsWith', label: 'Начинается с' },
    { value: 'EndsWith', label: 'Заканчивается на' }
  ],
  'Boolean': [
    { value: 'Equal', label: 'Равно', selected: true }
  ],
  'Int16': [
    { value: 'Equal', label: 'Равно', selected: true },
    { value: 'More', label: 'Больше' },
    { value: 'Less', label: 'Меньше' },
    { value: 'MoreOrEqual', label: 'Больше или равно' },
    { value: 'LessOrEqual', label: 'Меньше или равно' },
    { value: 'Between', label: 'Между' }
  ],
  'Int32': [
    { value: 'Equal', label: 'Равно', selected: true },
    { value: 'More', label: 'Больше' },
    { value: 'Less', label: 'Меньше' },
    { value: 'MoreOrEqual', label: 'Больше или равно' },
    { value: 'LessOrEqual', label: 'Меньше или равно' },
    { value: 'Between', label: 'Между' }
  ],
  'Int64': [
    { value: 'Equal', label: 'Равно', selected: true },
    { value: 'More', label: 'Больше' },
    { value: 'Less', label: 'Меньше' },
    { value: 'MoreOrEqual', label: 'Больше или равно' },
    { value: 'LessOrEqual', label: 'Меньше или равно' },
    { value: 'Between', label: 'Между' }
  ],
  'Single': [
    { value: 'More', label: 'Больше', selected: true },
    { value: 'Less', label: 'Меньше' },
    { value: 'MoreOrEqual', label: 'Больше или равно' },
    { value: 'LessOrEqual', label: 'Меньше или равно' },
    { value: 'Between', label: 'Между' }
  ],
  'Double': [
    { value: 'More', label: 'Больше', selected: true },
    { value: 'Less', label: 'Меньше' },
    { value: 'MoreOrEqual', label: 'Больше или равно' },
    { value: 'LessOrEqual', label: 'Меньше или равно' },
    { value: 'Between', label: 'Между' }
  ],
  'Decimal': [
    { value: 'Equal', label: 'Равно', selected: true },
    { value: 'More', label: 'Больше' },
    { value: 'Less', label: 'Меньше' },
    { value: 'MoreOrEqual', label: 'Больше или равно' },
    { value: 'LessOrEqual', label: 'Меньше или равно' },
    { value: 'Between', label: 'Между' }
  ],
  'DateOnly': [
    { value: 'Equal', label: 'Равно', selected: true },
    { value: 'More', label: 'Больше' },
    { value: 'Less', label: 'Меньше' },
    { value: 'MoreOrEqual', label: 'Больше или равно' },
    { value: 'LessOrEqual', label: 'Меньше или равно' },
    { value: 'Between', label: 'Между' }
  ],
  'TimeOnly': [
    { value: 'Equal', label: 'Равно', selected: true },
    { value: 'More', label: 'Больше' },
    { value: 'Less', label: 'Меньше' },
    { value: 'MoreOrEqual', label: 'Больше или равно' },
    { value: 'LessOrEqual', label: 'Меньше или равно' },
    { value: 'Between', label: 'Между' }
  ],
  'DateTime': [
    { value: 'Equal', label: 'Равно', selected: true },
    { value: 'More', label: 'Больше' },
    { value: 'Less', label: 'Меньше' },
    { value: 'MoreOrEqual', label: 'Больше или равно' },
    { value: 'LessOrEqual', label: 'Меньше или равно' },
    { value: 'Between', label: 'Между' }
  ],
  'Dictionary': [
    { value: 'Contains', label: 'Содержит' },
  ],
  'DictionaryHierarchical': [
    { value: 'Contains', label: 'Содержит' },
    { value: 'ContainsChildren', label: 'Содержит дочерние' },
  ]
};