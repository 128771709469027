import React, { useCallback, useEffect, useState } from 'react';
import { IActions, ObjectMap } from '@types';
import { CCard, CCardBody, CCardHeader } from '@coreui/react-pro';
import { Property } from 'csstype';
import { useDispatch, useSelector } from 'react-redux';
import storage from '@storage';
import ContentFilterCardItem from './content-filter-card-item';
import { TooltipIcon } from '@component';
import { useFormatTranslation } from '@hooks';

type CardConfig = {
  boxShadow?: Property.BoxShadow;
}

interface ContentFilterPanelProps {
  name?: string;
  label?: string;
  hidden?: boolean;
  tooltip?: boolean;
  card?: CardConfig;
  data: ObjectMap;
  setData?: (newData: ObjectMap) => void;
  permissions?: IActions<boolean>;
}

export default function ContentFilterCard(props: ContentFilterPanelProps) {
  const dispatch = useDispatch();
  const { t } = useFormatTranslation();
  const [json, setJson] = useState('null');
  const dropdownName = `${props.name}/${props.data.id ?? 0}`;

  const label = props.label ?? t('content_filter__card__header__default_label');
  const tooltip = props.tooltip ?? t('content_filter__card__header__default_tooltip');

  const organizationId = props.data.organization?.id ?? props.data.organizationId ?? props.data.organization;

  const properties = useSelector(storage.dropdown.selectItems(dropdownName));

  const isACSU = useSelector(storage.userAccessRights.selectIsACSU);

  const filters: ObjectMap[] = props.data.filters ?? [];

  const setFilters = useCallback((filters: ObjectMap[]) => {
    props.setData?.call(null, { ...props.data, filters });
  }, [props.data, props.setData]);

  const onAdd = useCallback(() => {
    setFilters([...filters, {}]);
  }, [filters, setFilters]);

  const onChange = useCallback((itemId: number, data: ObjectMap) => {
    const newFilters = [...filters];
    newFilters[itemId] = { ...filters[itemId], ...data };
    setFilters(newFilters);
  }, [filters, setFilters]);

  const onDelete = useCallback((itemId: number) => {
    setFilters(filters.filter((_, i) => itemId !== i));
  }, [filters, setFilters]);

  useEffect(() => {
    setJson(JSON.stringify(filters));
  }, [filters]);

  useEffect(() => {
    if (organizationId || !isACSU) {
      const params = { organizationId };
      dispatch(storage.dropdown.setProps(dropdownName, 'content-filter/dropdown', params, undefined, true));
      dispatch(storage.dropdown.loadData(dropdownName));
    }
  }, [organizationId, isACSU]);

  if (properties.length === 0) {
    return (
      <></>
    );
  }

  return (
    <div>
      <CCard
        hidden={props.hidden}
        style={{
          boxShadow: props.card?.boxShadow
            ? props.card.boxShadow
            : undefined
        }}
      >
        <CCardHeader>
          {label}
          <TooltipIcon content={tooltip} className="ms-2" />
        </CCardHeader>
        <CCardBody>
          {filters.map((item, i) => (
            <ContentFilterCardItem
              key={i}
              itemId={i}
              data={item}
              properties={properties}
              onChange={onChange}
              onDelete={onDelete}
              organizationId={organizationId}
              permissions={props.permissions}
            />
          ))}
          {filters.length > 0 && (
            <div className="mt-3" />
          )}
          {props.permissions?.create !== false && (
            <div className="link-info cursor-pointer text-decoration-underline float-start ms-2" onClick={onAdd}>
              {t('content_filter__card__body__add_item')}
            </div>
          )}
        </CCardBody>
        <input
          type="hidden"
          datatype="json"
          name={props.name}
          value={json}
        />
      </CCard>
    </div>
  );
}