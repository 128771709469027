import React, { useCallback, useMemo } from 'react';
import { CButton } from '@coreui/react-pro';
import { ControlProps } from '@smart-table/types';
import { useNavigate } from 'react-router-dom';
import { usePropertyValue } from '@hooks';
import Svg from '@svg';
import storage from '@storage';
import { useSelector } from 'react-redux';

function Button(props: ControlProps) {
  const item = props.item ?? {};
  const disabled = usePropertyValue(item, props.disabled);
  const hidden = usePropertyValue(item, props.hidden);

  const navigate = useNavigate();
  const keyName = useMemo(() => props.keyName ?? 'id', [props.keyName]);
  const permissions = useSelector(storage.userAccessRights.selectPermissions);

  const onClick = useCallback(() => {
    if (props.onClick) {
      props.onClick(item);
    } else {
      navigate(`edit/${item[keyName]}`);
    }
  }, [props.onClick, item, keyName]);

  if (props.permission && !permissions.includes(props.permission)) {
    return (
      <></>
    );
  }
  
  return (
    <CButton
      color={'dark'}
      size='sm'
      className='me-1 p-1 justify-content-center d-flex flex-column'
      onClick={onClick}
      disabled={disabled}
      hidden={hidden}
    >
      <Svg.Icon.EditPencil fill='white' />
    </CButton>
  );
}

type EditButton = typeof Button & { props: (props: ControlProps) => (props: ControlProps) => JSX.Element };
const EditButton: EditButton = Button as EditButton;
EditButton.props = (props: ControlProps) => (defProps: ControlProps) => <Button {...defProps} {...props} />;
export default EditButton;