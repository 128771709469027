import React, { useMemo } from 'react';
import { IOption, ObjectMap } from '@types';
import { DatePicker, FormInput, FormSelect, SmartFormMultiSelect } from '@component';
import { YesNoOptions } from '@options/yes-no-options';
import { CCol, CRow } from '@coreui/react-pro';

interface ContentFilterCardItemValueProps {
  itemId: number;
  data: ObjectMap;
  label?: string;
  hidden?: boolean;
  disabled?: boolean;
  onChange?: (newData: ObjectMap) => void;
  properties: IOption[];
  organizationId?: string;
}

export default function ContentFilterCardItemValue(props: ContentFilterCardItemValueProps) {
  const data = props.data;

  const col0_cn = data.conditionType === 'Between' ? 'pe-0' : undefined;

  const dictionaryType = useMemo(() => {
    return (props.properties.find(x => x.value === props.data.code) as any)?.dictionaryType as string | undefined;
  }, [props.properties, props.data.code]);

  switch (data.valueType) {
    case 'String': {
      return (
        <FormInput
          label={props.label}
          hidden={props.hidden}
          disabled={props.disabled}
          value={data.value ?? ''}
          onChange={e => props.onChange?.call(null, { ...data, value: e.target.value })}
        />
      );
    }

    case 'Boolean': {
      return (
        <FormSelect
          label={props.label}
          hidden={props.hidden}
          disabled={props.disabled}
          value={data.value ? 'true' : 'false'}
          options={YesNoOptions}
          onChange={e => props.onChange?.call(null, { ...data, value: e.target.value === 'true' })}
        />
      );
    }

    case 'Int16':
    case 'Int32':
    case 'Int64':
    case 'Single':
    case 'Double':
    case 'Decimal': {
      return (
        <CRow>
          <CCol className={col0_cn}>
            <FormInput
              type="number"
              label={props.label}
              hidden={props.hidden}
              disabled={props.disabled}
              value={data.value ?? 0}
              onChange={e => props.onChange?.call(null, {
                ...data,
                value: e.target.valueAsNumber,
                value2: data.conditionType === 'Between' ? data.value2 : undefined
              })}
            />
          </CCol>
          {data.conditionType === 'Between' && (
            <CCol>
              <FormInput
                type="number"
                label={props.label}
                hidden={props.hidden}
                disabled={props.disabled}
                value={data.value2 ?? 0}
                onChange={e => props.onChange?.call(null, {
                  ...data,
                  value2: e.target.valueAsNumber
                })}
              />
            </CCol>
          )}
        </CRow>
      );
    }

    case 'DateOnly': {
      return (
        <CRow>
          <CCol className={col0_cn}>
            <DatePicker
              label={props.label}
              hidden={props.hidden}
              disabled={props.disabled}
              date={data.value ?? null}
              onDateChange={date => props.onChange?.call(null, {
                ...data,
                value: date?.toISOString().substring(0, 10),
                value2: data.conditionType === 'Between' ? data.value2 : undefined
              })}
              className="pt-4"
            />
          </CCol>
          {data.conditionType === 'Between' && (
            <CCol>
              <DatePicker
                label={props.label}
                hidden={props.hidden}
                disabled={props.disabled}
                date={data.value2 ?? null}
                onDateChange={date => props.onChange?.call(null, {
                  ...data,
                  value2: date?.toISOString().substring(0, 10)
                })}
                className="pt-4"
              />
            </CCol>
          )}
        </CRow>
      );
    }

    case 'TimeOnly': {
      return (
        <></>
      );
    }

    case 'DateTime': {
      return (
        <CRow>
          <CCol className={col0_cn}>
            <DatePicker
              label={props.label}
              hidden={props.hidden}
              disabled={props.disabled}
              date={data.value ?? null}
              onDateChange={date => props.onChange?.call(null, {
                ...data,
                value: date?.toISOString(),
                value2: data.conditionType === 'Between' ? data.value2 : undefined
              })}
              className="pt-4"
              timepicker
            />
          </CCol>
          {data.conditionType === 'Between' && (
            <CCol>
              <DatePicker
                label={props.label}
                hidden={props.hidden}
                date={data.value2 ?? null}
                disabled={props.disabled}
                onDateChange={date => props.onChange?.call(null, {
                  ...data,
                  value2: date?.toISOString()
                })}
                className="pt-4"
                timepicker
              />
            </CCol>
          )}
        </CRow>
      );
    }

    case 'Dictionary':
    case 'DictionaryHierarchical':{
      return (
        <CRow>
          <CCol className={props.itemId > 0 ? 'pt-4': undefined}>
            <SmartFormMultiSelect
              name={`dropdown/${dictionaryType}`}
              actionUrl={'dropdown'}
              params={{
                organizationId: props.organizationId,
                type: dictionaryType
              }}
              label={props.label}
              hidden={props.hidden}
              disabled={props.disabled}
              hideDisabledOptions
              value={data.value}
              onChange={options => {
                props.onChange?.call(null, {
                  ...data,
                  value: options?.map(x => x.value)
                });
              }}
            />
          </CCol>
        </CRow>
      );
    }

    default: {
      return <></>;
    }
  }
}