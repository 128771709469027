import React from 'react';
import { CButton } from '@coreui/react-pro';
import { useNavigate } from 'react-router-dom';
import { ToolbarButtonProps } from '@toolbar';
import { useSelector } from 'react-redux';
import storage from '@storage';

function Button(props: ToolbarButtonProps) {
  const navigate = useNavigate();
  const permissions = useSelector(storage.userAccessRights.selectPermissions);
  
  if (props.permission && !permissions.includes(props.permission)) {
    return (
      <></>
    );
  }
  
  return (
    <CButton color={props.color ?? 'primary'} onClick={() => navigate('create')}>
      {props.text ?? 'Добавить'}
    </CButton>
  );
}

type CreateButton = typeof Button & { props: (props: ToolbarButtonProps) => () => JSX.Element };
const CreateButton: CreateButton = Button as CreateButton;
CreateButton.props = (props: ToolbarButtonProps) => () => <Button {...props} />;
export default CreateButton;